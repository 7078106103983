<template>
  <div id="NotFound">
    <a-row>
      <a-col class="nav">
        <Nav></Nav>
      </a-col>
      <a-col class="content">
        <p class="message">
          Thank you for your interest in InnoCell! Please click below link to
          our new website.
        </p>
        <a
          class="btn btn-primary"
          href="https://www.hkstp.org/what-we-offer/infrastructure-overview/#innocell"
          >Click Here</a
        >
      </a-col>
    </a-row>
    <a-back-top />
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import CoCarousel from "@/components/CoCarousel";
import SmartLiving from "@/components/SmartLiving";
import Together from "@/components/Together";
export default {
  name: "Home",
  components: {
    Nav,
    CoCarousel,
    SmartLiving,
    Together,
  },
  data() {
    return {
      gifHeaderAnimation: require("../assets/img/gif/header_animation.gif"),
      gifBlueUnderline: require("../assets/img/gif/blue_underline_gif.gif"),
      gifBlueExclaimation: require("../assets/img/gif/blue_exclaimation_gif.gif"),
      gifBlueSmile: require("../assets/img/gif/blue_smile_animation.gif"),
      gifYellowBox: require("../assets/img/gif/yellow_box_gif.gif"),
      gitIntroCard: require("../assets/img/gif/intro_card.gif"),
      gitCircled: require("../assets/img/gif/circled.gif"),
      pngSpaces: require("../assets/img/spaces_background.png"),
      pngCommunity: require("../assets/img/community_background.png"),
      pngMan: require("../assets/img/man.png"),
      blueArrow: require("../assets/img/gif/blue_arrow_gif.gif"),
      vimeoURL: "https://vimeo.com/468811485/3a7edbd5de",
      green_squiggle_animation: require("../assets/img/gif/co/green_squiggle_animation.gif"),
      videoUrl: require("../assets/img/header_animation.mp4"),
    };
  },
};
</script>

<style lang="less">
#NotFound {
  .content {
    margin-top: 97px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .message {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .btn {
    display: inline-block;
  }
}
</style>
